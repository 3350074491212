<template>
	<div
	id="statistics"
	class="tw-p-[24px] tw-gap-[5px] tw-h-full tw-flex tw-flex-col"
	>
		<HeaderSlot
		title="Statistiques d'utilisation"
		subTitle="Fichiers"
		:toBackButton="{ name: 'workspaces' }"
		/>

		<v-tabs
		v-model="tab"
		align-with-title
		class="tw-h-[48px] tw-grow-0 tw-w-full tw-justify-center tw-flex"
		>
			<v-tabs-slider color="#2c0703"/>

			<v-tab
			v-for="t in tabs"
			:key="t"
			>
				{{ t }}
			</v-tab>
		</v-tabs>
		<div class="tw-outline-[1px] tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px]">
			<div
			class="tw-w-full tw-sticky tw-top-0 tw-bg-[white] tw-z-[2] tw-justify-center tw-p-[10px] tw-flex tw-flex-wrap tw-flex-row tw-gap-[10px]"
			>
				<v-select
				dense
				:items="agencies"
				v-model="selectedAgency"
				item-text="name"
				item-value="id"
				placeholder="Filtrer par agences"
				@change="applyAgency"
				clearable
				class="lg:tw-basis-[calc(50%-5px)] tw-basis-full"
				:loading="agenciesLoading"
				/>

				<v-select
				dense
				:items="filteredSites"
				v-model="selectedSite"
				item-text="name"
				item-value="id"
				placeholder="Filtrer par site"
				@change="applySite"
				clearable
				class="lg:tw-basis-[calc(50%-5px)] tw-basis-full"
				:loading="sitesLoading"
				/>
			</div>

			<div
			class="tw-w-full tw-sticky tw-top-0 tw-bg-[white] tw-z-[2] tw-justify-center tw-p-[10px] tw-flex tw-flex-wrap tw-flex-row tw-gap-[10px]"
			>
				<v-menu
				transition="scale-transition"
				offset-y
				min-width="auto"
				:close-on-content-click="false"
				ref="menu"
				v-model="menu"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-combobox
						v-model="startDate"
						label="Date de début"
						tabindex="7"
						prepend-inner-icon="mdi-calendar"
						readonly
						v-bind="attrs"
						v-on="on"
						outlined
						clearable
						:rules="[
							$rules.earlierDate(startDate, endDate)
						]"
						dense
						append-icon=""
						>
							<template v-slot:selection>
								{{ moment(startDate).format("Do MMMM YYYY") }}
							</template>
						</v-combobox>
					</template>

					<v-date-picker
					v-model="startDate"
					no-title
					scrollable
					@change="() => {
						menu = false;
						applyFilters()
					}"
					/>
				</v-menu>

				<v-menu
				transition="scale-transition"
				offset-y
				min-width="auto"
				:close-on-content-click="false"
				ref="menu2"
				v-model="menu2"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-combobox
						v-model="endDate"
						label="Date de fin"
						tabindex="8"
						prepend-inner-icon="mdi-calendar"
						readonly
						v-bind="attrs"
						v-on="on"
						outlined
						clearable
						:rules="[
							$rules.laterDate(startDate, endDate)
						]"
						dense
						append-icon=""
						>
							<template v-slot:selection>
								{{ moment(endDate).format("Do MMMM YYYY") }}
							</template>
						</v-combobox>
					</template>

					<v-date-picker
					v-model="endDate"
					no-title
					scrollable
					@change="() => {
						menu2 = false;
						applyFilters()
					}"
					/>
				</v-menu>
			</div>
		</div>

		<v-tabs-items
		v-model="tab"
		class="tw-w-full tw-justify-center tw-overflow-y-auto mt-4 tw-grow"
		>
			<v-tab-item
			class="tw-w-full"
			key="Carottage"
			>
				<div
				class="tw-w-full tw-p-[1px] tw-flex tw-flex-wrap tw-gap-[10px]"
				>
					<div
					class="lg:tw-basis-[calc(50%-5px)] tw-basis-full tw-grow tw-shrink tw-outline-[1px] tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-flex tw-flex-col tw-justify-between tw-items-center"
					>
						<div
						id="byStatus"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>
					
					<div
					class="tw-outline-[1px] lg:tw-basis-[calc(50%-5px)] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="finishedCampaignsByMonth"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] lg:tw-basis-full tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="byMonthAndStatus"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full lg:tw-basis-[calc(50%-5px)] tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="trialByProviderAreia"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full lg:tw-basis-[calc(50%-5px)] tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="trialByProviderEurofins"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="byMonthAndDiameter"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="usersByDay"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="usersByOrganizationsByDay"
						class="tw-w-full tw-h-[600px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						id="usersByOrganizationsByMonth"
						class="tw-w-full tw-h-[600px]"
						/>
					</div>
				</div>
			</v-tab-item>

			<v-tab-item
			class="tw-w-full"
			key="Auscultation"
			>
				<div 
				class="tw-w-full tw-p-[1px] tw-flex tw-flex-wrap tw-gap-[10px]"
				>
					<div
					class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						ref="A1"
						id="A1"
						class="tw-w-full tw-h-[380px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						ref="A2"
						id="A2"
						class="tw-w-full tw-h-[600px]"
						/>
					</div>

					<div
					class="tw-outline-[1px] tw-basis-full tw-grow tw-shrink tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-basis-full"
					>
						<div
						ref="A3"
						id="A3"
						class="tw-w-full tw-h-[600px]"
						/>
					</div>
				</div>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import amchartUtils from "../utils/amcharts.utils.js";
export default {
	name: "Statistics",
	data(){
		return {
			globalStats: {},
			byAgencies: {
				asbestos: [],
				pah: [],
				th: []
			},
			byStatuses: [],
			byMonthAndDiameter: [],
			byMonthAndStatus: [],
			byDates: [],
			deliveriesByMonth: [],
			trialByProvider: {
				AREIA: [],
				EUROFINS: [],
				ADLAB: []
			},
			trialTypes: [
				"asbestos", "pah", "th"
			],
			colors: [
				"#EEEEEE",
				"#F5D5AE",
				"#FEBE8C",
				"#F9FFA4",
				"#C4DFAA",
				"#90C8AC",
				"#7FB77E"
			],
			monthAndStatusColors: [
				"#EEEEEE",
				"#F5D5AE",
				"#FEBE8C",
				"#F9FFA4",
				"#C4DFAA",
				"#90C8AC",
				"#7FB77E"
			],
			simpleColor: "#F5D5AE",
			trialsColor: [
				"#F4DFD0",
				"#DAD0C2",
				"#CDBBA7"
			],
			diameterColor: [
				"#F4DFD0",
				"#DAD0C2",
				"#CDBBA7",
				"#DBBEBA",
				"#CEDDCA",
				"#d0d0ff"
			],
			agencies: [],
			selectedAgency: null,
			sites: [],
			selectedSite: null,
			filteredSites: [],
			sitesLoading: true,
			agenciesLoading: true,
			tab: "Carottage",
			tabs: ["Carottage", "Auscultation"],
			globalStatsAuscultation: [],
			allUsersByDays: [],
			globalStatsDay: [],
			globalStatsMonth: [],
			allUsersByOrganizationByMonth: [],
			allUsersByOrganizationByDay: [],
			menu: false,
			menu2: false,
			startDate: null,
			endDate: null
		};
	},
	watch: {
		tab(){
			if(this.tab === 1){
				this.generateGraphsDataAuscultation();
			}
		}
	},
	methods: {
		applyFilters(){
			this.byStatuses = [];
			this.byMonthAndDiameter = [];
			this.byMonthAndStatus = [];
			this.byDates = [];
			this.deliveriesByMonth = [];
			this.byAgencies.asbestos = [];
			this.byAgencies.pah = [];
			this.byAgencies.th = [];
			this.trialByProvider.AREIA = [];
			this.trialByProvider.EUROFINS = [];

			this.$api.statistics
			.getCoringCampaignsStatsV2(this.selectedAgency, this.selectedSite, this.startDate ?? null, this.endDate ?? null)
			.then(data => {
				this.globalStats = data;
				this.generateGraphsData();
				this.displayGraphs();
			});

			if(this.startDate !== null || this.endDate !== null){
				this.$api.statistics.getUsersStats("day", this.startDate, this.endDate)
				.then(response => {
					this.globalStatsDay = response;
					this.$api.statistics.getUsersStats("month", this.startDate, this.endDate)
					.then(response => {
						this.globalStatsMonth = response;
						this.displayGraphsCoringConnexion();
					});
				});
				
			}
		},
		applyAgency(){
			if(this.selectedAgency === null){
				this.filteredSites = this.sites;
			}
			else {
				this.filteredSites = this.sites.filter(
					site => site.agency.id === this.selectedAgency
				);
			}
			this.applyFilters();
		},
		applySite(){
			this.applyFilters();
		},
		// Charts generate data & display functions

		generateGraphsData(){
			if(Object.keys(this.globalStats).length === 0){
				return setTimeout(this.generateGraphsData, 500);
			}
			this.countByStatuses();
			this.coresByMonthAndDiameter();
			this.coresByMonthAndStatus();
			this.countByAgenciesAndProvider();
			this.finishedCampaignByMonth();
		},
		generateGraphsDataAuscultation(){
			if(undefined === this.$refs["A1"]){
				setTimeout(this.generateGraphsDataAuscultation, 500);
			}
			this.displayUsersByDays();
			this.displayUsersOrganizationsByMonth();
			this.displayUsersOrganizationsByDay();
		},
		displayGraphsCoringConnexion(){
			this.displayUsersByDaysCoring();
			this.displayUsersOrganizationsByMonthCoring();
			this.displayUsersOrganizationsByDayCoring();
		},
		displayGraphs(){
			if(Object.keys(this.globalStats["countByStatuses"]).length !== 0) this.createPieChart(
				"byStatus",
				this.byStatuses,
				"Campagnes par statut"
			);
			if(Object.keys(this.globalStats["coresByMonthAndDiameter"]).length !== 0) this.createColumnNBarStackedChart(
				"byMonthAndDiameter",
				this.byMonthAndDiameter,
				"Carottes par mois et par diamètre",
				"V",
				"Carottes",
				this.diameterColor
			);
			if(Object.keys(this.globalStats["coresByMonthAndStatus"]).length !== 0) this.createColumnNBarStackedChart(
				"byMonthAndStatus",
				this.byMonthAndStatus,
				"Carottes par mois et par statut",
				"V",
				"Nombre de carottes",
				this.monthAndStatusColors
			);
			if(Object.keys(this.globalStats["countByProvider"]).length !== 0) this.createColumnNBarStackedChart(
				"trialByProviderAreia",
				this.trialByProvider.AREIA,
				"Essais AREIA par mois",
				"V",
				"Nombre d'essais",
				this.trialsColor
			);
			if(Object.keys(this.globalStats["countByProvider"]).length !== 0) this.createColumnNBarStackedChart(
				"trialByProviderEurofins",
				this.trialByProvider.EUROFINS,
				"Essais EUROFINS par mois",
				"V",
				"Nombre d'essais",
				this.trialsColor
			);
			if(
				Object.keys(this.globalStats["finishedCampaignsByMonth"]).length !== 0
			) this.createColumnNBarSimpleChart(
				"finishedCampaignsByMonth",
				this.deliveriesByMonth,
				"Livrables par mois",
				"V",
				"Nombre de rapports livrés",
				this.simpleColor
			);
		},

		// Charts data manipulations functions
		getSerieKeys(datas, excludedKeys = []){
			let allKeys = [];
			datas.forEach(data => {
				Object.keys(data).forEach(key => {
					if(!excludedKeys.includes(key) && !allKeys.includes(key)){
						allKeys.push(key);
					}
				});
			});
			return allKeys;
		},
		countByStatuses(){
			if(Object.keys(this.globalStats["countByStatuses"]).length !== 0){
				this.byStatuses = Object.keys(this.globalStats["countByStatuses"])
				.map((key, index) => [
					{
						name: this.$t(key),
						value: this.globalStats["countByStatuses"][key],
						type: "Pie",
						color: this.colors[index]
					}
				])
				.flat();
			}
		},
		coresByMonthAndDiameter(){
			if(
				Object.keys(this.globalStats["coresByMonthAndDiameter"]).length !== 0
			){
				let tempArray = Object.values(
					this.globalStats["coresByMonthAndDiameter"]
				);
				let iterator = Object.keys(this.globalStats["coresByMonthAndDiameter"]);
				tempArray.forEach((element, index) => {
					this.byMonthAndDiameter.push({
						"50 mm": element[50],
						"80 mm": element[80],
						"100 mm": element[100],
						"150 mm": element[150],
						"200 mm": element[200],
						"250 mm": element[250],
						type: iterator[index]
					});
				});
			}
		},
		coresByMonthAndStatus(){
			if(Object.keys(this.globalStats["coresByMonthAndStatus"]).length !== 0){
				let tempArray = Object.values(
					this.globalStats["coresByMonthAndStatus"]
				);
				let iterator = Object.keys(this.globalStats["coresByMonthAndStatus"]);
				tempArray.forEach((element, index) => {
					this.byMonthAndStatus.push({
						Annulées: element.cancelled,
						"En attente": element.waiting,
						Extraites: element.extracted,
						"Envoyées au chargé d'affaires": element.sent_to_study_manager,
						Validées: element.validated,
						"Envoyées au prestataire": element.sent_to_provider,
						"Résultats reçus": element.results_acquired,
						type: iterator[index]
					});
				});
			}
		},
		countByAgenciesAndProvider(){
			if(Object.keys(this.globalStats["countByProvider"]).length !== 0) Object.keys(this.globalStats["countByProvider"]).map(month => {
				this.trialByProvider.AREIA.push({
					Amiante:
              this.globalStats["countByProvider"][month]["AREIA"]["asbestos"],
					HAP: this.globalStats["countByProvider"][month]["AREIA"]["pah"],
					HCT: this.globalStats["countByProvider"][month]["AREIA"]["th"],
					type: month
				});
				if(this.globalStats["countByProvider"][month]["EUROFINS"] !== undefined){
					this.trialByProvider.EUROFINS.push({
						Amiante:
              this.globalStats["countByProvider"][month]["EUROFINS"]["asbestos"],
						HAP: this.globalStats["countByProvider"][month]["EUROFINS"]["pah"],
						HCT: this.globalStats["countByProvider"][month]["EUROFINS"]["th"],
						type: month
					});
				}
			});
		},
		finishedCampaignByMonth(){
			if(
				Object.keys(this.globalStats["finishedCampaignsByMonth"]).length !== 0
			){
				let allDeliveries = [];
				Object.keys(this.globalStats["finishedCampaignsByMonth"]).map(month => {
					let date =
            this.globalStats["finishedCampaignsByMonth"][month]["date"];
					let count =
            this.globalStats["finishedCampaignsByMonth"][month]["count"];
					let delivery = {};
					delivery["type"] = date;
					delivery["value"] = count;
					allDeliveries.push(delivery);
				});
				this.deliveriesByMonth = allDeliveries;
			}
		},
		usersConnectedByDays(){
			if(
				Object.keys(this.globalStatsDay["users"]).length !== 0
			){
				let allUsersByDays = [];
				Object.keys(this.globalStatsDay["users"]).reverse().forEach(day => {
					let checkAuscultation = this.globalStatsDay["users"][day].filter(e => e.auscultation === true);
					allUsersByDays.push({type: day, value: checkAuscultation.length});
					
				});
				this.allUsersByDays = allUsersByDays;
			}
		},
		usersConnectedByOrganizationsByDay(){
			if(
				Object.keys(this.globalStatsDay["usersByOrganizations"]["auscultation"]).length !== 0
			){
				let allUsersByOrganizationByDay = [];
				Object.keys(this.globalStatsDay["usersByOrganizations"]["auscultation"]).reverse().forEach((day, index) => {
					allUsersByOrganizationByDay[index] = [];
					allUsersByOrganizationByDay[index]["type"] = day;
					Object.keys(this.globalStatsDay["usersByOrganizations"]["auscultation"][day]).forEach(organization => {
						if(this.globalStatsDay["usersByOrganizations"]["auscultation"][day][organization].length !== 0) allUsersByOrganizationByDay[index][organization] = this.globalStatsDay["usersByOrganizations"]["auscultation"][day][organization].length;
					});
				});
				this.allUsersByOrganizationByDay = allUsersByOrganizationByDay;
			}
		},
		usersConnectedByOrganizationsByMonth(){
			if(
				Object.keys(this.globalStatsMonth["usersByOrganizations"]["auscultation"]).length !== 0
			){
				let allUsersByOrganizationByMonth = [];
				Object.keys(this.globalStatsMonth["usersByOrganizations"]["auscultation"]).reverse().forEach((month, index) => {
					allUsersByOrganizationByMonth[index] = [];
					allUsersByOrganizationByMonth[index]["type"] = month;
					Object.keys(this.globalStatsMonth["usersByOrganizations"]["auscultation"][month]).forEach(organization => {
						if(this.globalStatsMonth["usersByOrganizations"]["auscultation"][month][organization].length !== 0) allUsersByOrganizationByMonth[index][organization] = this.globalStatsMonth["usersByOrganizations"]["auscultation"][month][organization].length;
					});
				});

				this.allUsersByOrganizationByMonth = allUsersByOrganizationByMonth;
			}
		},
		displayUsersByDays(){
			this.usersConnectedByDays();
			this.createColumnNBarSimpleChart(
				"A1",
				this.allUsersByDays,
				"Nombre d'utilisateurs client connectés par jour sur les 31 derniers jours",
				"V",
				"Connexions",
				this.simpleColor
			);
		},
		displayUsersOrganizationsByDay(){
			this.usersConnectedByOrganizationsByDay();
			this.createColumnNBarStackedChart(
				"A2",
				this.allUsersByOrganizationByDay,
				"Nombre d'utilisateurs client connectés par organisations par jours",
				"H",
				"Connexions"
			);
		},
		displayUsersOrganizationsByMonth(){
			this.usersConnectedByOrganizationsByMonth();
			this.createColumnNBarStackedChart(
				"A3",
				this.allUsersByOrganizationByMonth,
				"Nombre d'utilisateurs client connectés par organisations par mois",
				"H",
				"Connexions"
			);
		},

		usersConnectedByDaysCoring(){
			if(
				Object.keys(this.globalStatsDay["users"]).length !== 0
			){
				let allUsersByDays = [];
				Object.keys(this.globalStatsDay["users"]).reverse().forEach(day => {
					let checkCoring = this.globalStatsDay["users"][day].filter(e => e.coring === true);
					allUsersByDays.push({type: day, value: checkCoring.length});
					
				});
				this.allUsersByDays = allUsersByDays;
			}
		},
		usersConnectedByOrganizationsByDayCoring(){
			if(
				Object.keys(this.globalStatsDay["usersByOrganizations"]["coring"]).length !== 0
			){
				let allUsersByOrganizationByDay = [];
				Object.keys(this.globalStatsDay["usersByOrganizations"]["coring"]).reverse().forEach((day, index) => {
					allUsersByOrganizationByDay[index] = [];
					allUsersByOrganizationByDay[index]["type"] = day;
					Object.keys(this.globalStatsDay["usersByOrganizations"]["coring"][day]).forEach(organization => {
						if(this.globalStatsDay["usersByOrganizations"]["coring"][day][organization].length !== 0) allUsersByOrganizationByDay[index][organization] = this.globalStatsDay["usersByOrganizations"]["coring"][day][organization].length;
					});
				});
				this.allUsersByOrganizationByDay = allUsersByOrganizationByDay;
			}
		},
		usersConnectedByOrganizationsByMonthCoring(){
			if(
				Object.keys(this.globalStatsMonth["usersByOrganizations"]["coring"]).length !== 0
			){
				let allUsersByOrganizationByMonth = [];
				Object.keys(this.globalStatsMonth["usersByOrganizations"]["coring"]).reverse().forEach((month, index) => {
					allUsersByOrganizationByMonth[index] = [];
					allUsersByOrganizationByMonth[index]["type"] = month;
					Object.keys(this.globalStatsMonth["usersByOrganizations"]["coring"][month]).forEach(organization => {
						if(this.globalStatsMonth["usersByOrganizations"]["coring"][month][organization].length !== 0) allUsersByOrganizationByMonth[index][organization] = this.globalStatsMonth["usersByOrganizations"]["coring"][month][organization].length;
					});
				});

				this.allUsersByOrganizationByMonth = allUsersByOrganizationByMonth;
			}
		},
		displayUsersByDaysCoring(){
			this.usersConnectedByDaysCoring();
			this.createColumnNBarSimpleChart(
				"usersByDay",
				this.allUsersByDays,
				"Nombre d'utilisateurs client connectés par jour sur les 31 derniers jours",
				"V",
				"Connexions",
				this.simpleColor
			);
		},
		displayUsersOrganizationsByDayCoring(){
			this.usersConnectedByOrganizationsByDayCoring();
			this.createColumnNBarStackedChart(
				"usersByOrganizationsByDay",
				this.allUsersByOrganizationByDay,
				"Nombre d'utilisateurs client connectés par organisations par jours",
				"H",
				"Connexions"
			);
		},
		displayUsersOrganizationsByMonthCoring(){
			this.usersConnectedByOrganizationsByMonthCoring();
			this.createColumnNBarStackedChart(
				"usersByOrganizationsByMonth",
				this.allUsersByOrganizationByMonth,
				"Nombre d'utilisateurs client connectés par organisations par mois",
				"H",
				"Connexions"
			);
		},

		// Charts generation functions

		createPieChart(id, data, title){
			amchartUtils.createPieSimpleChart(id, data, title);
		},
		createColumnNBarSimpleChart(ref, data, title, direction, yAxis, color = null){
			amchartUtils.createColumnNBarSimpleChart(
				ref,
				data,
				color,
				this.getSerieKeys(data, "type"),
				title,
				direction,
				yAxis,
				false,
				true,
				0
			);
		},
		createColumnNBarStackedChart(ref, data, title, direction, axisLabel, color = []){
			amchartUtils.createColumnNBarStackedChart(
				ref,
				data,
				color,
				this.getSerieKeys(data, "type"),
				title,
				direction,
				axisLabel,
				0,
				true
			);
		}
	},
	mounted(){
		this.$api.statistics
		.getCoringCampaignsStatsV2(this.selectedAgency, this.selectedSite)
		.then(data => {
			this.globalStats = data;
			this.generateGraphsData();
			this.displayGraphs();
			this.$api.agencies.findAll().then(data => {
				this.agencies = data.sort((a, b) => a.name.localeCompare(b.name));
				this.agenciesLoading = false;
			});
			this.$api.organizations
			.getAllSitesFromOrganization("6e42295a-a6e4-43b8-84d9-d37c5d9db7b3")
			.then(data => {
				this.sites = data.sort((a, b) => a.name.localeCompare(b.name));
				this.filteredSites = data;
				this.sitesLoading = false;
			});
		});
		this.$axios.get("/stats/external-users-connexions?period=day")
		.then(response => {
			this.globalStatsDay = response.data;
			this.$axios.get("/stats/external-users-connexions?period=month")
			.then(response => {
				this.globalStatsMonth = response.data;
				this.displayGraphsCoringConnexion();
			});
		});
		
	}
};
</script>
