<template>
	<div class="CreatePopin">
		<v-card
		flat
		outlined
		class="tw-flextw-flex-col"
		>
			<h3 class="tw-mb-[10px]">
				{{ editVersion === null ? 'Ajouter une nouvelle version' : 'Modifier la version' }}
			</h3>
			<v-form
			class="tw-flex tw-flex-col tw-gap-[10px] "
			ref="form"
			@submit.prevent="confirmModifications"
			>
				<v-text-field
				dense
				autofocus
				tabindex="1"
				outlined
				label="Numéro de version *"
				hide-details
				v-model="versionNumber"
				/>

				<quill-editor
				v-model="versionDescription"
				:options="editorOptions"
				/>

				<div class="tw-flex tw-flex-row tw-gap-[10px]">
					<span>
						Rendre la version visible par le client ?
					</span>

					<v-switch
					class="tw-mt-[0px] tw-pt-[0px]"
					style="flex-direction: row-reverse !important;"
					hide-details
					v-model="visibleCustomer"
					/>
				</div>

				<div class="tw-flex tw-flex-row tw-mt-[10px]">
					<ButtonSlot @click="closePopin">
						Annuler
					</ButtonSlot>

					<v-spacer/>

					<ButtonSlot
					@click="editVersion === null ? createMaorieVersion() : editMaorieVersion()"
					>
						Valider
					</ButtonSlot>
				</div>
			</v-form>
		</v-card>
	</div>
</template>

<script>
import {quillEditor} from "vue-quill-editor";

// Import styles for Quill
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
	components: {
		quillEditor
	},
	props: {
		editVersion: {
			type: Object,
			required: false
		}
	},
	data(){
		return {
			versionNumber: null,
			versionDescription: null,
			visibleCustomer: false,
			editorOptions: {
				theme: "snow", // or 'bubble'
				modules: {
					toolbar: [
						[
							{
								"header": [
									1, 2, false
								]
							}
						],
						[
							"bold", "italic", "underline"
						],
						[{"list": "ordered"}, {"list": "bullet"}],
						["link"],
						[{"align": []}],
						[{"color": []}, {"background": []}],
						["clean"] // remove formatting button
					]
				}
			}
		};
	},
	methods: {
		createMaorieVersion(){
			this.$api.maorieVersion.create({
				versionNumber: this.versionNumber,
				versionDescription: this.versionDescription,
				visibleCustomer: this.visibleCustomer
			}).then(e => {
				this.clearForm();
				this.$emit("closeAndRefresh");
			});
		},
		editMaorieVersion(){
			this.$api.maorieVersion.edit(this.editVersion.id, {
				versionNumber: this.versionNumber,
				versionDescription: this.versionDescription,
				visibleCustomer: this.visibleCustomer
			}).then(e => {
				this.clearForm();
				this.$emit("closeAndRefresh");
			});
		},
		clearForm(){
			this.versionNumber = null;
			this.versionDescription = null;
			this.visibleCustomer = false;
		},
		closePopin(){
			this.$emit("closePopin");
		}
	},
	mounted(){
		this.clearForm();
		if(this.editVersion !== null){
			this.versionNumber = this.editVersion.version;
			this.versionDescription = this.editVersion.versionNote;
			this.visibleCustomer = this.editVersion.isVisibleCustomer;
		}
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.CreatePopin {
	@extend %popinView;
}

.custom-switch {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.custom-switch .v-input__control {
  flex-direction: row-reverse !important;
}
</style>
