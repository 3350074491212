<template>
	<div class="tw-flex tw-h-full tw-p-[24px] tw-flex-col">
		<NewVersion
		v-if="displayPopup"
		@closePopin="displayPopup=false"
		@closeAndRefresh="closeAndRefresh()"
		:editVersion="editVersion"
		/>
		<HeaderSlot
		title="Notes de versions MAORIE"
		:toBackButton="{ name: 'workspaces' }"
		>
			<ButtonSlot
			@click="displayPopup = true"
			v-if="$hasRight('users.isSuperAdmin')"
			>
				Ajouter une nouvelle version
			</ButtonSlot>
		</HeaderSlot>

		<div class="tw-flex tw-grow tw-overflow-y-auto tw-flex-col tw-gap-[10px] tw-pr-[5px] tw-p-[24px]">
			<table class="border">
				<thead>
					<th
					class="tw-p-[10px] border"
					>
						Date de la version
					</th>
					<th
					class="tw-p-[10px] border"
					>
						Descriptif de la version
					</th>
				</thead>
				<tbody>
					<tr v-for="version in versions">
						<td
						class="tw-p-[24px] border tw-w-[200px]"
						>
							#################################<br/>
							# @Date : {{ moment(version.createdAt).format('DD/MM/Y') }}<br/>
							# @Version : {{ version.version }}<br/>
							#################################
						</td>
						<td
						class="tw-p-[24px] border tw-relative"
						>
							<div v-html="formattedText(version)"/>
							<ButtonSlot
							_icon="mdi-pencil"
							class="tw-absolute tw-top-[10px] tw-right-[10px]"
							@click="editVersion = version; displayPopup = true"
							v-if="$hasRight('users.isSuperAdmin')"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import NewVersion from "../components/popup/NewVersion.popup.vue";

export default {
	components: {NewVersion},
	data(){
		return {
			versions: [],
			displayPopup: false,
			editVersion: null
		};
	},
	methods: {
		formattedText: function(version){
			return version.versionNote.replace(/\n/g, "<br/>");
		},
		async getVersions(){
			this.versions = await this.$api.maorieVersion.getAll();
		},
		closeAndRefresh(){
			this.displayPopup = false;
			this.getVersions();
		}
	},
	async mounted(){
		await this.getVersions();
	},
};
</script>

<style scoped>
.border {
	border:1px solid black; 
	border-collapse: collapse;
}
</style>
